












































import FamilySelect from './FamilySelect.vue'
import FormMixin from '@/mixins/FormMixin'

export default FormMixin.extend({
  components: {
    FamilySelect
  }
})
